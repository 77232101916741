
import { Component, Vue } from 'vue-property-decorator'

@Component

export default class Record extends Vue {
  private msgTypeNameList = []
  private searchInfo = {
    typeName: '',
    projectName: '',
    readState: '',
    startTime: '',
    endTime: '',
    msg: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '类别',
        prop: 'typeName',
        minWidth: '80',
        showOverflowTooltip: true
      },
      {
        label: '消息内容',
        prop: 'msg',
        minWidth: '150',
        showOverflowTooltip: true
      },
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '发送时间',
        prop: 'sendTime',
        minWidth: '150',
        showOverflowTooltip: true
      },
      {
        label: '接收人',
        prop: 'receiverName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '消息状态',
        prop: 'readState',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private total = 0
  private page = 1
  private size = 10
  private dateArr: Array<string> = []
  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 6 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  created () {
    this.initTime()
    this.getData()
    this.getTypeNameList()
  }

  initTime () {
    this.dateArr = [this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss'), this.$dayjs().format('YYYY-MM-DD HH:mm:ss')]
  }

  getTypeNameList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'msgTypeName' }).then(res => {
      this.msgTypeNameList = res.msgTypeName
    })
  }

  getData () {
    if (this.dateArr && this.dateArr[0] && this.dateArr[1]) {
      this.searchInfo.startTime = this.dateArr[0]
      this.searchInfo.endTime = this.dateArr[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
    this.tableData.loading = true
    this.$axios.get(this.$apis.messageRecord.recordPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
